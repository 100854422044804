
    import { defineComponent, ref, reactive, toRefs, createVNode, PropType, computed } from 'vue';
    import {
        CheckCircleOutlined,
        CloseCircleOutlined,
        CheckCircleFilled,
        CloseCircleFilled,
        ExclamationCircleOutlined
    } from '@ant-design/icons-vue';
    import { Modal } from 'ant-design-vue';
    import { message } from "ant-design-vue";
    import { Options, FileItem } from '../types';
    import { 
        getCurrentBu,
        retailSaleImport,
        retailSaleImportSpecial,
        retailSaleImportLog
    } from "@/API/rv/rvRetailData";
    import { RetailDataType } from "../types";
    import blobDownload from "@/utils/blobDownload";
    import useFetch from "@/hooks/useFetch";
    import { downloadFromUrl } from "@/utils/payment/downloadFile";

    //type 字段'special' | 'normal' 来区分业务
    export default defineComponent({
        name: "retailDataImportContent",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            type: {
                type: Object as PropType<RetailDataType>
            }
        },
        emits: ['update:visible', 'upload-success'],
        components: { CheckCircleOutlined, CloseCircleOutlined },
        setup(props, { emit }) {
            const uploadParameter = reactive<{ retailMonth: string; entity: string; file: FileItem | null }>({
                retailMonth: '',
                entity: '',
                file: null
            });

            const uploadResult = reactive({
                id: '',
                errorMessage: []
            })

            const fileInfo = reactive({
                fileName: '',
                size: '',
                isBeforeUpload: false,
                uploadRate: 0,
                uploadComplete: false,
                uploadResult: null
            })

            const { data } = useFetch<{ bu: string; [key: string]: any }>({}, { bu: '' }, getCurrentBu, false, (res: any) => {
                console.log(res.bu);
                uploadParameter.entity = !res.bu ? 'MBCL&BBAC' : res.bu;
            });

            const title = computed(() => {
                return props.type === 'normal' ? 'Upload Retail Consistency Data' : 'Upload Special Usage List';
            })

            const handlerBrowseClick = () => {
                Object.assign(fileInfo, {
                    fileName: '',
                    size: '',
                    isBeforeUpload: false,
                    uploadRate: 0,
                    uploadComplete: false,
                    uploadResult: null
                })
                Object.assign(uploadParameter, { file: null });
                Object.assign(uploadResult, {
                    id: '',
                    errorMessage: []
                })
            }

            const onClose = (): void => {
                emit('update:visible', false);
                //清空所有的参数
                handlerBrowseClick();
                Object.assign(uploadParameter, {
                    retailMonth: '',
                    file: null
                })
                Object.assign(uploadResult, {
                    id: '',
                    errorMessage: []
                })
            }

            const showConfirm = (content: string, type: string,  callback?: Function) => {
                Modal.info({
                    title: 'Tips',
                    class: 'modal-info',
                    icon: createVNode(type === 'error' ? CloseCircleFilled : CheckCircleFilled, { style: { color:  type === 'error' ? 'red' : '#008A00' } }),
                    content: content,
                    closable: true,
                    centered: true,
                    onOk() {
                        callback && callback();
                    },
                    onCancel() {
                        callback && callback();
                    }
                });
            };

            const beforeUpload = (file: FileItem) => {
                const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!isXlsx) {
                    showConfirm('Only xlsx format files can be uploaded!', 'error');
                    return ;
                }
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    showConfirm('The file size should not be larger than 5MB!', 'error');
                    return ;
                }
                //文件的基本信息
                uploadParameter.file = file;
                fileInfo.size = file.size / 1024 > 1024 ? `${(file.size / 1024 / 1024).toFixed(1)} MB` : `${Math.floor(file.size / 1024)} KB`;
                fileInfo.fileName = file.name as string;
                fileInfo.isBeforeUpload = true;
                return false;
            };

            const handleUpload = () => {
                //验证之后才type === 'normal'
                if (props.type === 'normal' && (!uploadParameter.file || !uploadParameter.entity || !uploadParameter.retailMonth)) {
                    message.error('Please select the retail month and entity!');
                    return;
                }
                const formData = new FormData();
                for (const key in uploadParameter) {
                    uploadParameter[key] && formData.append(key, uploadParameter[key]);
                }

                const importAPI = props.type === 'normal' ? retailSaleImport : retailSaleImportSpecial;

                importAPI(formData, {
                    headers: {
                        "code-handler": "ignore",
                    },
                    onUploadProgress: (progressEvent: any) => {
                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                        //如果lengthComputable为false，就获取不到e.total和e.loaded
                        if (progressEvent.lengthComputable) {
                            const rate = (progressEvent.loaded / progressEvent.total) * 100;
                            //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                            //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                            //等响应回来时，再将进度设为100%
                            if (rate < 100) {
                                fileInfo.uploadRate = rate;
                            }
                        }
                    }
                }).then(res => {
                    fileInfo.uploadComplete = true;
                    fileInfo.uploadRate = 100;
                    Object.assign(uploadResult, { id: res.data?.id, errorMessage: res.data?.errorMessage });
                    if (res.code === "0" && (res.data === null || res.data.errorMessage?.length === 0)) {
                        const message = props.type === 'normal' ? 'Upload successfully' : 'Upload successfully, please go to the retail validation page for checking results';
                        showConfirm(message, 'success', () => {
                            onClose();
                            emit('upload-success');
                        });
                    }
                    if (res.code !== "0") {
                        if (props.type === 'special' && res.code === '50015') {
                          Modal.confirm({
                              title: 'Confirm',
                              icon: createVNode(ExclamationCircleOutlined),
                              content: res.message,
                              okText: 'Download',
                              async onOk() {
                                  const url = await retailSaleImportLog(res.data.id);
                                  downloadFromUrl(url);
                              }
                          });
                        } else {
                            message.error(res.message);
                            onClose();
                        }
                    }
                }).catch(err => {
                    fileInfo.uploadComplete = true;
                    fileInfo.uploadRate = 100;
                })
            };

            const handlerDownloadTemplate = () => {
                blobDownload({ url: `/saleapi/import/template?type=${props.type}` })
            }

            return {
                onClose,
                title,

                beforeUpload,
                handleUpload,
                handlerBrowseClick,
                handlerDownloadTemplate,

                ...toRefs(uploadParameter),
                ...toRefs(fileInfo),
                ...toRefs(uploadResult)
            }
        }
    })
