
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import moment, { Moment } from "moment";
import { VerticalAlignBottomOutlined } from "@ant-design/icons-vue";
import useTableHeight from "@/hooks/useTableHeight";

import Pagination from "@/components/Pagination.vue";
import RetailDataImportContent from "@/views/RetailValidation/RetailData/components/RetailDataImportContent.vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { s3Download } from "@/utils/s3Download";
import useFetch from "@/hooks/useFetch";
import { getRetailDataList } from "@/API/rv/rvRetailData";
import {
  Options,
  RetailDataQueryParameter,
  RetailData,
  RetailDataType,
} from "./types";
import { message } from "ant-design-vue";
type Key = ColumnProps["key"];
import { useStore } from "vuex";
const columns = [
  {
    title: "Retail Month",
    dataIndex: "retailMonth",
    key: "retailMonth",
    width: 160,
    slots: { customRender: "retailMonth" },
  },
  {
    title: "Entity",
    dataIndex: "entity",
    key: "entity",
    width: 160,
  },
  {
    title: "Filename",
    dataIndex: "fileName",
    key: "filename",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Dealer Count",
    dataIndex: "dealerCount",
    key: "dealerCount",
    width: 160,
  },
  {
    title: "VIN Count",
    dataIndex: "vinCount",
    key: "vinCount",
    width: 160,
  },
  {
    title: "Import Time",
    dataIndex: "importTime",
    key: "importTime",
    slots: { customRender: "importTime" },
    width: 200,
  },
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
    width: 160,
  },
  {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    slots: { customRender: "operation" },
    width: 160,
  },
];

export default defineComponent({
  name: "retailData",
  components: {
    Pagination,
    RetailDataImportContent,
    VerticalAlignBottomOutlined,
  },
  setup() {
    const { tableHeight } = useTableHeight();

    const retailDataType = ref<RetailDataType>("normal");

    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: RetailData[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: RetailData[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
    });

    const entityOptions = ref<Options[]>([
      { name: "FBAC", value: "FBAC" },
      { name: "DENZA", value: "DENZA" },
      { name: "All", value: "" },
    ]);

    const versionOptions = ref<Options[]>([
      { name: "Final", value: "Final" },
      { name: "All", value: "" },
    ]);

    const mode = ref<string[]>(["month", "month"]);

    const queryParameter = reactive<RetailDataQueryParameter>({
      startMonth: "",
      endMonth: "",
      entity: "",
      version: "",
      pageSize: 20,
    });

    const visible = ref(false);

    const handlerResetClick = (): void => {
      Object.assign(queryParameter, {
        startMonth: "",
        endMonth: "",
        entity: "",
        version: "",
      });
    };

    // 判断当前用户有VAN
    const buTreeList = useStore().state.user.buTreeList;
    const findVANinUser = () => {
      let bool = false;
      buTreeList.forEach((item: any) => {
        if (item.nameCn === "VAN") {
          bool = true;
        }
      });
      return bool;
    };
    // const data = ref()
    const { data, pagination, refreshFetch } = useFetch<RetailData[]>(
      queryParameter,
      [],
      getRetailDataList,
      true,
      (res: any) => {
        if (!findVANinUser()) {
          res.totalCount = 0;
          res.content = [];
          res.pageSize = 10;
        }
      }
    );

    const handlerImportClick = (type: string): void => {
      visible.value = !visible.value;
    };
    const handlePickerChange = (val: Moment[]) => {
      queryParameter.startMonth = (
        val[0] ? val[0].format("YYYY/MM") : val[0]
      ) as string;
      queryParameter.endMonth = (
        val[1] ? val[1].format("YYYY/MM") : val[1]
      ) as string;
    };

    const handlePickerPanelChange = (val: Moment[], mode: string[]) => {
      queryParameter.startMonth = (
        val[0] ? val[0].format("YYYY/MM") : val[0]
      ) as string;
      queryParameter.endMonth = (
        val[1] ? val[1].format("YYYY/MM") : val[1]
      ) as string;
      mode = [
        mode[0] === "date" ? "month" : mode[0],
        mode[1] === "date" ? "month" : mode[1],
      ];
    };

    const handlerDownloadClick = (docId: string) => {
      s3Download({
        url: `/saleapi/import/download/${docId}`,
        method: "get",
      }).then((res) => {
        console.log(res);
      });
    };

    const handlerBatchDownload = () => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error("Please select Retail Data");
        return;
      }
      s3Download({
        url: `/saleapi/import/zip/download`,
        method: "post",
        data: {
          docList: rowSelection.selectedRowData.map((item) => ({
            docId: item.docId,
          })),
        },
      }).then((res) => {
        console.log(res);
      });
    };

    const resetTableSelectData = (): void => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
    };

    const hanlderSearchClick = () => {
      refreshFetch(1);
      resetTableSelectData();
    };

    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
      pagination.page = page;
      refreshFetch();
      resetTableSelectData();
    };

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
      pagination.pageSize = pageSize;
      Object.assign(pagination, { page: current, pageSize: pageSize });
      refreshFetch();
      resetTableSelectData();
    };

    return {
      entityOptions,
      versionOptions,

      tableHeight,
      rowSelection,

      columns,
      data,
      pagination,

      handlerResetClick,
      handlerImportClick,
      handlePickerPanelChange,
      handlePickerChange,
      handlerDownloadClick,
      handlerBatchDownload,
      handlerPaginationChange,
      handlerShowSizeChange,
      hanlderSearchClick,

      visible,
      retailDataType,

      mode,

      ...toRefs(queryParameter),

      moment,
      findVANinUser,
    };
  },
});
