import { fetchGet, fetchPut, fetchPostPic } from "@/API/index";
import { RetailDataQueryParameter, RetailDataUploadRes } from "@/views/RetailValidation/RetailData/types";

export function getRetailDataList(
  params: RetailDataQueryParameter
): Promise<any> {
  return fetchGet("/saleapi/import/uploadlist", params);
}

export function updateUploadFileStatus(docId: string): Promise<string> {
  return fetchPut(`/saleapi/import/status/${docId}`);
}

export function getCurrentBu() {
  return fetchGet(`/saleapi/import/userBu/special`);
}

export function getSpeicalUsageList(params: any): Promise<any> {
  return fetchGet("/saleapi/page/special/usage/list", params);
}

export function retailSaleImport(data: FormData, config = {}): Promise<RetailDataUploadRes> {
  return fetchPostPic("/saleapi/import/upload", data, config)
}

export function retailSaleImportSpecial(data: FormData, config = {}): Promise<RetailDataUploadRes> {
  return fetchPostPic("/saleapi/import/upload/special", data, config)
}

export function retailSaleImportLog(logId: string): Promise<string> {
  return fetchGet(`/saleapi/import/log/download/${logId}`)
}