
import { defineComponent, ref } from "vue";
import RetalDataUpload from "./RetailDataUpload.vue";
import SpecialVehicle from "./SpecialVehicle.vue";

export default defineComponent({
  components: {
    RetalDataUpload,
    SpecialVehicle,
  },
  setup() {
    const activeKey = ref(1);

    return { activeKey };
  },
});
